<template>
        <div class="usermanage-root">
            <el-row>
                <el-table :data="accountList" style="width:352px;"  v-loading="loading" stripe border size="small"
                    height="256px"
                    :header-row-style="{
                        height:'12px',
                        backgroundColor:'gray'
                    }" show-overflow-tooltip>
                    <el-table-column type=" selection"></el-table-column>
                    <el-table-column prop="userInfo" label="用户名" width="128"></el-table-column>
                    <el-table-column prop="userName" label="登录ID" width="96"></el-table-column>
                    <el-table-column prop="password" label="密码" width="128">
                        <template #default="scope">
                            <el-input  show-password type="password" v-model="scope.row.password">
                            </el-input>
                        </template>
                    </el-table-column>
                    <el-table-column></el-table-column>
                </el-table>
            </el-row>
            <el-row></el-row>
        </div>
</template>

<script>
export default {
    name:'UserManage',
    data(){
        return{
            accountList:[],
            selectList:[],
            loading:false
        }
    }
}
</script>

<style lang="less" scoped>
.usermanage-root{
    width:800px;
}
</style>